<template>
  <!-- 信息录入--新建用户 -->
  <div class="buildUser">
    <el-dialog :title="dialogType" :visible.sync="displayObj.isshow" width="70%"
      :close-on-click-modal="false" :append-to-body="true" class="buildNew" @close='closeDialog'>
      <!-- 内部dialog -->
      <el-dialog width="40%" title="地图" :visible.sync="isMap.isshow" :close-on-click-modal="false"
        :append-to-body="true" class="map_dialog">
        <!-- 搜索页面 -->
        <div id="search_ipt">
          <searchs @shareLoc="shareLoc"></searchs>
        </div>
        <!-- map地图页面 -->
        <maps :isMap="isMap" @getlocations="getlocations"></maps>
        <div slot="footer" class="dialog-footer">
          <el-button @click="isMap.isshow = false">取 消</el-button>
          <el-button type="primary" @click="closeMap">确定</el-button>
        </div>
      </el-dialog>
      <!-- 表单 -->
      <el-form :model="form" ref="form" label-width="100px" class="demo-ruleForm">
        <!-- 营业执照号   -->
        <el-form-item label="营业执照号" class="cardid">
          <el-input v-model="form.cardid" placeholder="请输入社会信用代码或个人身份证"></el-input>
        </el-form-item>
        <!-- 企业名称 -->
        <el-form-item label="企业名称" class="cardid">
          <el-input v-model="form.gname"></el-input>
        </el-form-item>
          <!-- 主体链接 -->
          <el-form-item label="主体链接" class="cardid">
            <el-input v-model="form.gurl"></el-input>
          </el-form-item>
        <!-- 客户类型 -->
        <el-form-item label="客户类型" class="cardid">
          <el-select v-model="form.gtype" style="width: 100%;">
            <el-option :label="item.label" :value="item.value" v-for="item in clientType" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- 所属代理商 -->
        <el-form-item label="所属代理商" class="cardid">
          <el-input v-model="form.cid" disabled></el-input>
        </el-form-item>
        <!-- 跟进状态 -->
        <el-form-item label="跟进状态" class="cardid">
          <el-select v-model="form.status" style="width: 100%;">
            <el-option :label="item.label" :value="item.value" v-for="item in followStatus" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- 所在区域 -->
        <el-form-item label="所在区域" class="cardid">
          <el-cascader :options="areaOptions" v-model="form.selectedOptions" @change="handleChange"
            style="width: 100%;"></el-cascader>
        </el-form-item>
        <!-- 地图 -->
        <!-- 输入详细地址 -->
        <el-form-item class="map">
          <el-input v-model="form.addr" autocomplete="off" placeholder="请地图搜索详细地址" suffix-icon="el-icon-map-location"
            style="width: 100%;"></el-input>
          <div class="btns" @click="displayMap"></div>
        </el-form-item>
        <!-- 邮箱 -->
        <el-form-item label="邮箱" class="cardid">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <!-- 项目负责人 -->
        <el-form-item label="项目负责人" class="cardid">
          <el-input v-model="form.guser"></el-input>
        </el-form-item>
        <!-- 联系电话 -->
        <el-form-item label="联系电话" class="cardid">
          <el-input v-model="form.gphone"></el-input>
        </el-form-item>
        <!-- 紧急联系人 -->
        <el-form-item label="紧急联系人" class="cardid">
          <el-input v-model="form.gjuser"></el-input>
        </el-form-item>
        <!-- 紧急联系电话 -->
        <el-form-item label="紧急联系电话" class="cardid" label-width="120px">
          <el-input v-model="form.gjphone"></el-input>
        </el-form-item>
        <!-- 销售员 -->
        <el-form-item label="销售员" class="cardid">
          <el-input v-model="form.saler"></el-input>
        </el-form-item>
        <!-- 联系方式 -->
        <el-form-item label="联系电话" class="cardid">
          <el-input v-model="form.salephone"></el-input>
        </el-form-item>
        <!-- 合同上传 -->
        <el-form-item label="合同上传" class="contract_upload">
          <el-upload class="avatar-uploader" :show-file-list="true" :on-change="documentUpload"
            :action="$baseUrl + '/api/upload/annex/tract/up'" :file-list="fileList" :auto-upload='false'
            :limit="3" :on-exceed="handleExceed" :on-preview="clickFile">
            <el-button plain icon="el-icon-upload2">文件上传</el-button>
            <div slot="tip" class="el-upload__tip">*仅支持png、jpg、jpeg、pdf格式</div>
          </el-upload>
        </el-form-item>
        <!-- 证件上传 -->
        <el-form-item label="证件上传" class="contract_upload1">
          <!-- 图片上传1 -->
          <el-upload class="avatar-uploader2" :show-file-list="false" :on-change="uploadFile"
            :action="$baseUrl + '/api/upload/annex/tract/up'" :auto-upload='false'>
            <div class="documents">
              <img v-if="file1Url" :src="file1Url" alt="" style="width:84px;height:47px;">
              <i v-else class="el-icon-circle-plus"></i>
            </div>
          </el-upload>
          <!-- 图片上传2 -->
          <el-upload class="avatar-uploader2" :show-file-list="false" :on-change="aptitudeUpload"
          :action="$baseUrl + '/api/upload/annex/tract/up'" multiple :auto-upload='false'>
            <div class="documents">
              <img v-if="file2Url" :src="file2Url" alt="" style="width:84px;height:47px;">
              <i v-else class="el-icon-circle-plus"></i>
            </div>
          </el-upload>
        </el-form-item>
        <div></div>
        <!-- 备注说明 -->
        <el-form-item label="备注说明" class="remark">
          <el-input type="textarea" v-model="form.remark" maxlength="200" show-word-limit palceholder="请填写备注信息">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-botton>{{ form.selectedOptions }}</el-botton> -->
        <el-button @click="resetForm()">取 消</el-button>
        <el-button type="primary" @click="edit" v-if="dialogType == '用户编辑'">修 改</el-button>
        <el-button type="primary" @click="bulidNewUser" v-else>确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入地图组件
import maps from "./map.vue";
// 引入搜索页面
import searchs from "./search.vue";
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
// 引入消息提示
import { msg } from "../../../common/public";
// 导入二级联动
import { provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
import { Message } from "element-ui";
import { createNewUser, editUser, fileUpload } from "@/utils/api";

// 引入地址截取和地址转坐标
import { getaddr, textToCode, checkInpput2, uploadImg, fileuploads } from "../../../assets/js/public";


export default {
  components: {
    maps,//地图组件
    searchs,//搜索组件
  },
  props: ['displayObj', 'userInform', 'cid', 'dialogType'],
  data () {
    return {
      detailValue: '',//用户输入的详细地址
      areaOptions: provinceAndCityDataPlus,//所在区域的二级联动信息
      detailOptions: regionDataPlus,//详细地址的三级联动
      isMap: {//地图的dialog的显示
        isshow: false
      },
      file1Url: '',//证件上传--证件1
      file2Url: '',//证件上传--证件2
      fileList: [],
      form: {
        cardid: '',//营业执照号 唯一编码，公司为统一的社会信用代码，个人的话则是个人身份证
        gname: '',//企业名称
        gurl: '',//主体链接
        gtype: '',//客户类型
        cid: 1,//所属代理商
        selectedOptions: [],//所在区域--选择的地区
        prov: '',//省份
        city: '',//城市
        status: '',//跟进状态
        detailValue: '',//用户输入的地址
        addr: '',//详细地址
        guser: '',//项目负责人
        gphone: '',//负责人联系方式
        saler: '',//销售员
        salephone: '',//销售员联系方式
        remark: '',//备注信息
        lon: '1',//经度
        lat: '1',//纬度
        gjuser: '',//紧急联系人
        gjphone: '',//紧急联系人的联系方式
        annex: [],// 合同
        tract: [], //证件
        email: ''
      },
      timer: null,
      locations: [],
    };
  },
  computed: {
    ...mapState('customerInformation', ['clientType', 'followStatus',])
  },
  mounted () {
  },
  updated () {
    if (this.$parent.cid == 1) {
      this.form.cid = "平台自有"
    } else {
      this.form.cid = "非平台自有"
    }
  },
  watch: {
    userInform (nVal) {
      console.log('dakai');
      Object.keys(this.form).forEach(e => {
        this.form[e] = nVal[e] || this.form[e]
      })
      this.form.selectedOptions = []
      // 先将省市拼接
      let addrStr = this.form.prov + this.form.city
      let arr = getaddr(addrStr);
      this.form.selectedOptions = textToCode(arr, this.form.selectedOptions);
      // 文件,图片
      try {
        this.form.annex = JSON.parse(this.form.annex);
        this.fileList = this.form.annex.map(e => ({ name: e.name, url: this.$URL + e.url }))
        this.form.tract = JSON.parse(this.form.tract)
        this.file1Url = this.$URL + this.form.tract[0]
        this.file2Url = this.$URL + this.form.tract[1]
      } catch (e) {
        this.form.annex = []
        this.fileList = []
        this.form.tract = []
        this.file1Url = ''
        this.file2Url = ''
      }
      
    },
  },
  methods: {
    // 修改用户信息
    edit () {
      if(!checkInpput2(this.form)) return
      editUser({...this.form, id: this.userInform.id, annex: JSON.stringify(this.form.annex), tract: JSON.stringify(this.form.tract)}).then(res => {
        if (res.code == 1) {
          Message.success('修改成功')
          this.timer = setTimeout(() => {
            this.displayObj.isshow = false
          })
          // 重置表单
          this.resetForm()
        } else {
          Message.warning(res.msg)
          return
        }
        // 刷新数据
        this.$parent.getUserList()
      }).catch(err => {
        return console.log(err);
      })
    },
    // 将search组件中的输入框内容传递过来
    shareLoc (loc) {
      this.form.addr = loc
    },
    // 获取详细地址
    getlocations (locations) {
      this.locations = locations
    },
    //关闭地图
    closeMap () {
      console.log('位置', this.locations);
      if(this.locations.length === 0) return Message.warning('请选择地址')
      this.form.addr = this.locations[1];
      this.form.lon = this.locations[0][0];
      this.form.lat = this.locations[0][1];
      this.isMap.isshow = false
    },
    // 新建用户
    bulidNewUser () {
      this.form.lon && (this.form.lon = '1')
      this.form.lat && (this.form.lat = '1')
      if(!checkInpput2(this.form)) return
      if (this.form.cid == '平台自有') {
        this.form.cid = 1
      } else if (this.form.cid == '非平台自有') {
        this.form.cid = 0
      }
      createNewUser({...this.form,annex: JSON.stringify(this.form.annex), tract: JSON.stringify(this.form.tract)}).then(res => {
        if (res.code == 1) {
          console.log(res.data)//生成的用户、登录账号、密码
          Message.success('添加成功')
          this.timer = setTimeout(() => {
            // 关闭窗口
            this.displayObj.isshow = false
          }, 1500)
          //重置表单
          this.resetForm()
        } else {
          Message.warning(res.msg)
          return false
        }
        //刷新数据
        this.$parent.getUserList()
      }).catch(err => {
        return console.log(err);
      })
    },
    // 点×关闭弹窗,并清空输入框
    closeDialog () {
      for (let i in this.form) {
        if (i == 'selectedOptions' || i == 'annex' || i == 'tract') {
          this.form[i] = []
        } else {
          this.form[i] = '';
        }    
      };
      this.form.cid = this.cid
      this.fileList = []
      this.file1Url = ''
      this.file2Url = ''
    },
    // 重置表单
    resetForm () {
      // 获取所有表单的值
      // for (let i in this.form) {
      //   if (i == 'selectedOptions' || i == 'annex' || i == 'tract') {
      //     this.form[i] = []
      //   } else {
      //     this.form[i] = '';
      //   }    
      // };
      this.fileList = []
      this.file1Url = ''
      this.file2Url = ''
      this.displayObj.isshow = false
    },
    // 所在区域的二级联动
    handleChange () {
      let location = "";//所在区域
      if (this.form.selectedOptions[0] == '') { //说明第一个地址选择了全部,需要提示他选择地址
        msg('请选择你所在省', 'error')
        return false
      };
      if (this.form.selectedOptions[1] == '') { //在选择第二项地址时选择了全部，未详细 提醒
        msg('请选择你所在市', 'error')
        return false
      }
      // 省
      // 将地区码转话为地址
      for (let i = 0; i < this.form.selectedOptions.length; i++) {
        location += CodeToText[this.form.selectedOptions[i]];
      }
      let sInd = location.search('省');
      if (sInd == -1) {//找不到省
        let shiInd = location.search('市');
        this.form.prov = location.slice(0, shiInd + 1)
        this.form.city = location.slice(shiInd + 1);
      } else {//找的到省
        this.form.prov = location.slice(0, sInd + 1);
        this.form.city = location.slice(sInd + 1);
      }
    },
    //最多只能添加三份合同文件
    handleExceed () {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 点击合同上传中的文件
    clickFile (file) {
      window.open(file.url, false)
    },
    // 合同上传
    documentUpload (file) {
      let fileObj = fileuploads(file);
      let { name } = fileObj
      fileUpload({ file: fileObj, uid: 2, id: 1, type: 1 }, true).then(res => {
        if (res.code == 1) {
          let url = res.data.data
          this.form.annex.push({name,url})
          let obj = { 'name': name, 'url': `${this.$URL}${url}` }
          this.fileList.push(obj)
        }
      }).catch(err => {
        return console.log(err)
      })
    },
    // 证件上传--证件上传1
    uploadFile (file) {
      let fileObj = uploadImg(file);
      this.file1Url = URL.createObjectURL(fileObj);
      fileUpload({ file: fileObj, uid: 2, id: 1, type: 2 }, true).then(res => {
        if (res.code == 1) {
          let url = res.data.data
          this.form.tract.push(url);
        }
      }).catch(err => {
        return console.log(err)
      })
    },
     // 证件上传--证件上传2
    aptitudeUpload (file) {
      let fileObj = uploadImg(file);
      this.file2Url = URL.createObjectURL(fileObj);
      fileUpload({ file: fileObj, uid: 2, id: 1, type: 2 }, true).then(res => {
        if (res.code == 1) {
          let url = res.data.data
          this.form.tract.push(url);
        }
      }).catch(err => {
        return console.log(err)
      })
    },
    // 展示地图
    displayMap () {
      this.isMap.isshow = true;
    }
  },
};
</script>

<style scoped>
/*滚动条样式*/
.buildNew::-webkit-scrollbar {
  width: 4px;
}
.buildNew::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.buildNew::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.map_dialog /deep/ .el-dialog {
  margin-top: 2vh !important;
}
/* dialog主体部分的内边距 */
::v-deep .el-dialog__body {
  padding: 30px;
}
::v-deep .el-form {
  display: grid;
  grid-template-columns: 33% 33% 34%;
}
/* 文件上传 */
.contract_upload /deep/ .el-upload__tip {
  font-size: 12px;
  color: #b2b2b2;
  font-family: "SHSCN_Regular";
  margin-top: 0px;
}
.contract_upload /deep/ .el-button {
  border: 1px solid transparent;
  position: relative;
  top: -6px;
  padding: 12px 2px;
}
.contract_upload .el-button /deep/ span {
  color: #0666f5;
  font-size: 16px;
}
.contract_upload .el-button /deep/ i {
  color: #0666f5;
  font-size: 16px;
}
.contract_upload /deep/ img {
  margin: 0 0 0.7vh 0;
}
.contract_upload /deep/ .el-form-item__content {
  line-height: 0;
  margin: 1vh 0 0 0;
}
/* 证件上传 */
.contract_upload1 /deep/ .el-form-item__content {
  display: flex;
}
.avatar-uploader2 {
  margin-right: 20px;
}
.contract_upload1 .documents {
  width: 84px;
  height: 47px;
  background-color: #f1f3f8;
  border-radius: 5px 5px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contract_upload1 .documents .el-icon-circle-plus {
  font-size: 0.9vw;
  color: #4888ff;
}
/* 搜素页面 */
#search_ipt {
  position: absolute;
  z-index: 999;
  /* border: 1px solid #333; */
  position: absolute;
  top: 100px;
  left: 38px;
}

.buildUser /deep/ .el-dialog__title {
  font-family: "SHSCN_Bold";
}

/* 控制dialog的margin-top */
.buildNew /deep/ .el-dialog {
  margin-top: 3vh !important;
}
.el-form /deep/ .el-input__inner {
  height: 4.5vh;
}

/*营业执照号 */
.cardid /deep/ .el-form-item__label::before {
  content: "*";
  color: red;
}

.map /deep/ .el-form-item__content {
  margin-left: 30px !important;
}

.map /deep/ .el-input__icon {
  font-size: 16px;
  color: #0666f5;
}

/* 备注 */
.remark /deep/ .el-textarea__inner {
  width: 230%;
  height: 75px;
  resize: none;
}

.remark .el-textarea /deep/ .el-input__count {
  background-color: transparent;
  bottom: 0;
  right: -128%;
}

/* 输入框小图标的行高 */
::v-deep .el-input__icon {
  line-height: 20%;
}

/* 装地图小图标的小盒子 */
.map .btns {
  width: 25px;
  height: 3.9vh;
  margin: 0;
  position: absolute;
  bottom: 10%;
  right: 1%;
  display: inline-block;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
</style>