<template>
  <!-- 信息录入 -->
  <div class="customer_information">
    <!-- 板块中心区域 -->
    <div class="customerInfor_mid">
      <div class="customerInfor_header">
        <!-- 标题 -->
        <div class="title">
          <img src="../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="">
          <p v-for="(item,index) in $route.meta" :key="index">{{ item }}</p>
        </div>
        <!-- 选项框和按钮 -->
        <div class="option_btn">
          <div class="options">
            <!-- Element ui--Select 选择器 -->
            <!-- 客户查询 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">客户查询</span>
              <el-input v-model="datas.gname" placeholder="客户编号/名称/代理商" class="customer_ipt"
                @input="InquireCur"></el-input>
            </template>
            <!-- 跟进状态 -->
            <template>
              <span style="margin:0 0.8vw 0 1.92vw">跟进状态</span>
              <el-select v-model="datas.status" placeholder="请选择" style="margin:0 1.45vw 0 0" @change="InquireStatus">
                <el-option v-for="item in options1" :key="item.id" :label="item.label" :value="item.value2">
                </el-option>
              </el-select>
            </template>
            <!-- 公司性质 -->
            <template>
              <span style="margin: 0 0.5vw 0 0;">公司性质</span>
              <el-select v-model="datas.gtype" placeholder="请选择" @change="InquireType">
                <el-option v-for="item in options2" :key="item.id" :label="item.label" :value="item.value3">
                </el-option>
              </el-select>
            </template>
          </div>
          <!-- Element UI--Button按钮 -->
          <div class="btns">
            <el-checkbox v-model="status1" @change="checkPintai">平台自有客户</el-checkbox>
            <el-button type="primary" style="margin-right: 1vw;background-color: #0666F5;"
              @click="newUser">新建用户</el-button>
          </div>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="gateway_form">
        <!-- 客户信息列表页 -->
        <lists :allData="allData" @getSize="getSize" @getPage="getPage" :datas="datas" :displayObj="displayObj" :loading="loading"
          @editInform="editInform"></lists>
        <!-- 新建用户表单页 -->
        <buildnewuser :displayObj='displayObj' :userInform="userInform" :cid="cid" :dialogType="dialogType"></buildnewuser>
      </div>
      <!-- 公司的基本信息 -->
      <div class="firm_inform"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// 引入列表页
import lists from "./components/lists.vue";
// 引入新建用户表单
import buildnewuser from "./components/buildNewUser";
// 引入api--获取用户列表
import { getAllList } from "@/utils/api";

export default {

  components: {
    lists,//客户信息列表页
    buildnewuser,//新建用户表单页
  },
  data () {
    return {
      status1: true,//平台自有用户 true--选中 false--未选中
      datas: {
        gname: '',//客户查询关键字
        status: '',//跟进状态
        gtype: '',//公司性质
        prov: '',//省份
        city: '',//城市
        page: 1,//当前页
        size: 10,//数据量
      },
      allData: {},//用户列表
      userInform: {},
      cid: 1,//所属代理商
      loading: true,//加载中
      dialogType: ''
    };
  },
  computed: {
    ...mapState('customerInformation', ['status', 'options1', 'options2', 'displayObj'])
  },
  mounted () {
    this.getUserList()
  },

  methods: {
    // 平台自由客户
    checkPintai () {
      if (this.status1) {
        this.cid = 1
      } else {
        this.cid = 0
      }
    },
    // 传递编辑信息
    editInform ({userInform, dialogType}) {
      this.userInform = userInform;
      this.dialogType = dialogType;
    },
    // 获取每页展示的数据条数
    getSize (size) {
      this.datas.size = size;
      this.getUserList()
    },
    // 获取当前页码
    getPage (page) {
      this.datas.page = page
      this.getUserList()
    },
    // 获取用户列表--所有数据
    getUserList () {
      this.loading = true
      getAllList(this.datas).then(res => {
        if (res.code == 1) {
          this.allData = res.data;
          // console.log(this.allData.data)
        }
      this.loading = false
      }).catch(err => {
        return console.log(err);
      })
    },
    // 查询客户
    InquireCur () {
      this.getUserList()
    },
    // 跟进状态
    InquireStatus () {
      this.getUserList()
    },
    // 公司性质
    InquireType () {
      this.getUserList()
    },
    // 平台子有用户
    newUser () {
      // 平台自有用户
      if (this.status1) {
        this.cid = 1
      } else {
        this.cid = 0
      }
      this.dialogType = '新建用户'
      this.displayObj.isshow = true
    },
  },
};
</script>

<style  scoped>
.customer_information {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: -30px 0 0 0;
}
.customer_information .customerInfor_mid {
  width: 100%;
  height: 100%;
}
/* 头部--开始 */
.customerInfor_mid .customerInfor_header {
  width: 100%;
  min-height: 134px;
  background-color: #fff;
}
.customerInfor_header .title {
  display: flex;
  align-items: flex-end;
}
.customerInfor_header .title img {
  width: 6px;
  height: 20px;
  margin: 20px 0 0 20px;
}
.customerInfor_header .title p {
  font-size: 20px;
  font-family: "SHSCN_Bold";
  margin: 1vw 0 0 0.6vw;
}
/* 选项框和按钮 */
.option_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option_btn /deep/ .el-input {
  width: 206px;
  height: 32px;
}
.options {
  height: 7.2vh;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.option_btn /deep/ .el-input__icon {
  line-height: 1.85vh;
}
.option_btn span {
  font-size: 16px;
  font-family: "SHSCN_Regular";
  color: #171717;
}
.btns {
  margin-top: 25px;
}
/* 单选框--label */
.el-checkbox /deep/ .el-checkbox__label {
  font-size: 16px;
  font-family: "SHSCN_Regular";
  color: #171717;
  margin: 0 1vw 0 0;
}
.el-checkbox /deep/ .el-checkbox__inner {
  border-radius: 50%;
}
.el-checkbox /deep/ .el-checkbox__inner::after {
  border: 0px;
  content: "";
  border-radius: 50%;
}
/* 头部--结束 */

/* 表格区域--开始 */
.customerInfor_mid .gateway_form {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: 1vh;
}
/* 表格区域--结束 */
.lists /deep/ .el-pagination {
  align-items: normal;
  margin-top: 14px;
  height: 50px !important;
}
</style>