<template>
  <!-- 信息录入-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table :data="tableData" v-loading="loading" style="width: 100%" :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#4888FF'}" :max-height="tableHeight">
        <!-- 索引序号 -->
        <el-table-column type="index" width="100" fixed></el-table-column>
        <!-- 营业执照号 -->
        <el-table-column prop="cardid" label="营业执照号" min-width="200">
        </el-table-column>
        <!-- 企业名称 -->
        <el-table-column prop="gname" label="企业名称" min-width="250">
        </el-table-column>
        <!-- 跟进状态 -->
        <el-table-column prop="status" label="跟进状态" min-width="200">
          <template slot-scope="scope">
            <p v-if="scope.row.status == 1 ">潜在客户</p>
            <p v-else-if="scope.row.status == 2">已合作</p>
            <p v-else>合作放弃</p>
          </template>
        </el-table-column>
        <!-- 公司性质 -->
        <el-table-column prop="gtype" label="公司性质" min-width="200">
          <template slot-scope="scope">
            <p v-if="scope.row.gtype == 1 ">私企</p>
            <p v-else-if="scope.row.gtype == 2">央国企</p>
            <p v-else>外企</p>
          </template>
        </el-table-column>
        <!-- 所在区域 -->
        <el-table-column prop="addr" label="所在区域" min-width="300">
        </el-table-column>
        <!-- 所属代理商 -->
        <!-- <el-table-column prop="cid" label="所属代理商" min-width="200">
          <template slot-scope="scope">
            <p v-if="scope.row.cid == 1 ">1</p>
            <p v-else>2</p>
          </template>
        </el-table-column> -->
        <!-- 创建时间 -->
        <el-table-column prop="time" label="创建时间" sortable min-width="200">
        </el-table-column>
        <!-- 详情 -->
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-link type="primary" style="margin-right:10px" @click="editUser(scope.row)">编辑</el-link>
            <!-- <el-link type="primary" @click="jumpToDetail(scope.row)">详情</el-link> -->
          </template>
        </el-table-column>
        <div slot="empty" style="text-align:center;">
          <el-empty description="当前暂无数据！" />
        </div>
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <template>
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page.sync="page" :page-sizes="pages" :page-size="datas.size"
        layout="total, prev, pager, next,sizes,jumper" :total="total">
      </el-pagination>
    </template>
  </div>
</template>

<script>

import { getUserDetail } from "@/utils/api";
import { Message } from "element-ui";
export default {
  props: ['allData', 'datas', 'displayObj', 'loading'],
  components: {

  },

  data () {
    return {
      tableData: [],//表格数据
      total: 0,//数据总量
      pages: [10, 20, 30, 50],
      page: 1,
      tableHeight: 0
    };
  },
  created() {
    this.tableHeight = document.documentElement.clientHeight - 300;
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = document.documentElement.clientHeight - 300;
    })
  },
  watch: {
    allData (nVal, oVal) {
      this.tableData = nVal.data;
      this.total = nVal.total
    }
  },
  methods: {
    // 编辑
    editUser (userInform) {
      let tag = true;  //tag = true 代表是编辑  tag = false 代表是新建用户
      userInform = { ...userInform, tag }
      // 将数据传递过去
      this.$emit('editInform', {userInform, dialogType: '用户编辑'})
      this.displayObj.isshow = true;
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row';
      } else if (rowIndex % 2 === 1) {
        return 'success-row';
      }
      return '';
    },
    // 分页的方法
    handleSizeChange (val) {
      this.$emit('getSize', val)
    },
    handleCurrentChange (val) {
      this.$emit('getPage', val)
    },
    // 跳转至某用户的详情页
    jumpToDetail (inform) {
      getUserDetail({ id: inform.id }).then(res => {
        if (res.data.code == 1) {
          // 将需要的传递的信息存储到sessionStorage
          let informs = JSON.stringify(res.data.data)
          sessionStorage.setItem('informs', informs);
          this.$router.push({ path: '/userDetail' })
        } else {
          Message.error('用户详细信息跳转失败')
        }
      }).catch(err => {
        return console.log(err)
      })
    }
  },
};
</script>

<style  scoped>
.lists {
  height: 100%;
  position: relative;
}
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}
.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}
.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  font-weight: normal;
}
.lists .el-pagination {
  position: absolute;
  height: 60px;
  bottom: 110px;
  right: 0;
  background-color: #fff;
}
.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}
.el-table /deep/ .el-table__cell {
  text-align: center;
  padding: 16px 0;
}
::v-deep .el-select__caret {
  height: 100% !important;
}
</style>